<template>
  <div class="pdf">
    <div class="close">
      <el-button
        type="primary"
        icon="el-icon-circle-close"
        circle
        @click="closeAction"
      ></el-button>
    </div>
    <el-tabs
      v-if="!zhoukaoName"
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane label="带答案" name="first"></el-tab-pane>
      <el-tab-pane label="不带答案" name="second"></el-tab-pane>
    </el-tabs>
    <div class="input" v-if="!zhoukaoName">
      <el-select clearable v-model="xueke" placeholder="学科">
        <el-option label="HTML5" value="HTML5大前端就业班V12"> </el-option>
        <el-option label="JAVA" value="Java分布式开发V12"> </el-option>
        <el-option label="机器视觉" value="机器视觉"></el-option>
        <el-option label="嵌入式" value="嵌入式"></el-option>
        <el-option label="Java全栈+AIGC开发" value="Java全栈AIGC开发"></el-option>
      </el-select>
      <el-input
        v-model="banji"
        placeholder="班级"
        :clearable="true"
        max="100"
      ></el-input>
      <el-select v-model="jieduan" placeholder="阶段名称" max="60">
        <el-option
          v-for="(item, index) in jieduans"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="yuefen"
        placeholder="月份"
        :clearable="true"
        max="60"
      ></el-input>
      <el-input
        v-model="zhoushu"
        placeholder="周数"
        :clearable="true"
        max="60"
      ></el-input>
      <el-select v-model="jiaoshi" placeholder="教室" max="60">
        <el-option
          v-for="item in classes"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="content" ref="pdf">
      <h2 v-if="!zhoukaoName">
        {{ xueke }}学科{{ banji }}班级{{ jieduan }}阶段{{ yuefen }}月第{{
          zhoushu
        }}周周考考题-研发{{ jiaoshi }}
      </h2>
      <h2 v-else>{{ zhoukaoName }}</h2>
      <div class="question_info" v-for="(item, index) in tms" :key="item.id">
        <!-- 题目的详情展示 -->
        <h3 :class="{ name: bold, normal: !bold }">
          {{ index + 1 }}.题目:{{ item.title }} (总分{{ item.score }})
        </h3>
        <div class="daan" v-if="show || daan">
          <div v-html="item.answer"></div>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button v-if="!daan" type="primary" plain @click="exportPDF"
        >导出</el-button
      >
    </div>
  </div>
</template>
<script>
import { downloadPDF } from "@/common/pdf.js";
export default {
  name: "PDF",
  data() {
    return {
      tms: [],
      activeName: "first",
      show: this.zhoukaoName == undefined ? true : false,
      xueke: "",
      jiaoshi: "",
      jieduan: "",
      banji: "",
      yuefen: "",
      zhoushu: "",
      bold: true,
      zhoukaoName: null,
      daan: false,
      classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
      jieduans: [],
      xuekes: [],
    };
  },
  watch: {
    xueke(newV) {
      if(newV == "Java全栈AIGC开发"){
        newV = "Java全栈+AIGC开发";
      }
      let jieduans = [];
      for (let i in this.xuekes) {
          let item = this.xuekes[i];
          if (item.kechengtixi == newV) {
            jieduans.push(item.jieduanming);
          }
        }
      this.jieduans = [...new Set(jieduans)];
    },
    async banji(newV) {
      this.banji = newV.toUpperCase();
    },
  },
  async created() {
    this.tms = this.$route.query.datas.tms;
    this.zhoukaoName = this.$route.query.zhoukaoName;
    this.show = this.zhoukaoName ? false : true;
    this.bold = this.show;
    this.daan = this.$route.query.daan ? true : false;
    let res = await this.$datas.getCourses;
    this.xuekes = res.data;
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
    handleClick(tab) {
      if (tab.label == "带答案") {
        this.show = true;
        this.bold = true;
      } else {
        this.show = false;
        this.bold = false;
      }
    },
    async exportPDF() {
      let zhoukaoName = this.zhoukaoName;
      if (!this.zhoukaoName) {
        
        //添加班级
        this.$datas.meta = { className: this.banji };
        //存储班级信息
        let res1 = await this.$datas.addBanji;
        console.log(res1);
        
        //添加考试记录
        zhoukaoName = `郑州校区${this.xueke}学科${this.banji}班级${this.jieduan}阶段${this.yuefen}月第${this.zhoushu}周周考考题-研发${this.jiaoshi}`;
        this.$datas.meta = {
          className: this.banji,
          xueke: this.xueke,
          jieduan: this.jieduan,
          zhoukao: `${this.banji}-${this.yuefen}-${this.zhoushu}`,
          zhoukaoName: `郑州校区${this.xueke}学科${this.banji}班级${this.jieduan}阶段${this.yuefen}月第${this.zhoushu}周周考考题-研发${this.jiaoshi}`.replace(/[\s&#|]+/g, ""),
          timu: JSON.stringify(this.tms),
        };
        let res = await this.$datas.addZKjilu;
        console.log(res);
      }
      downloadPDF(this.$refs.pdf, zhoukaoName);
    },
  },
};
</script>
<style lang="less">
.pdf {
  h2 {
    font-size: 25px;
  }
  .close,
  .btn {
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    .el-icon-circle-close {
      font-size: 20px;
    }
  }
  .question_info {
    margin: 15px 0;
    div {
      margin: 10px 0;
    }
    .daan {
      color: blue;
    }
  }
  .content {
    padding: 80px;
    padding-top: 10px;
    h2 {
      text-align: center;
      padding: 15px 0;
    }
  }
  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    * {
      margin: 0 5px;
    }
    .el-select .el-input__inner {
      min-width: 150px;
    }
  }
  .normal {
    font-weight: 200;
    font-size: 16px;
  }
}
</style>
